<template>
    <div id="app" ref="page">
        <div v-if="loadSuccess" style="width: 100%;position: relative;">
            <img class="back" id="back" :src="'https://oss.milounge.com.cn/'+back_id+'?x-oss-process=image/resize,w_750'"/>
            <img class="file" v-for="(item,index) in file_ids" v-bind:key="index"
                 :style="(index%2?'transform: rotate(2deg);right: 8.5vw;':'transform: rotate(-5deg);left:9.5vw;') + 'top:' + (94.5 + 89.2 * index) + 'vw;'"
                 :src="'https://oss.milounge.com.cn/'+item+'?x-oss-process=image/resize,w_500'"/>
        </div>
        <img v-show="querySuccess" id="canvasPic">
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
    name: 'App',
    data() {
        return {
            loadSuccess: false,
            querySuccess: false,
            back_id: '',
            file_ids: [],
        }
    },
    mounted() {
        let that = this
        this.back_id = this.getQueryVariable('back_id');
        this.file_ids = this.getQueryVariable('file_ids').split(',')
        this.loadSuccess = true
        setTimeout(function(){
            that.getImage()
        },3000)
    },
    methods: {
        getImage(){
            // html2canvas(this.$refs.page).then(img => {
            //     // 转成图片，生成图片地址(如需将图片转为file 文件，请自行处理)
            //     this.imgUrl = img.toDataURL("image/jpg"); //此处为转换后生成的图片地址
            //     console.log(this.imgUrl)
            // })
            document.body.scrollTop = 0;
            // 将页面滚动至顶部后再开始截图，才能保证截图的完整
            html2canvas(this.$refs.page, {
                allowTaint:false,
                useCORS: true,
                width: document.body.scrollWidth,
                height: document.body.scrollHeight
                // 实际体验中发现最好设置宽高为页面的宽高才能获得完整的截图
            }).then(canvas => {
                canvas.toBlob(() => {
                    // var imgBoxEle = document.getElementsByClassName('imgBox')[0]
                    // imgBoxEle.style.display = "block"


                    // 1、这段是pc浏览器上截图实现的方式
                    // this.imgUrl = URL.createObjectURL(blob)
                    // const aImg = document.createElement('a')
                    // aImg.href = this.imgUrl
                    // aImg.download = Date.now() + '.png'
                    // document.body.appendChild(aImg)
                    // aImg.click()
                    // document.body.removeChild(aImg)

                    // 2、将上面的全部注释改成这5行，可以截图，但出现截取不全
                    // this.imgUrl = URL.createObjectURL(blob)
                    // const aImg = document.createElement('img')
                    // aImg.style = 'border:1px solid #000;width: 300px;height: auto;position:fixed;top:0;left:0;'
                    // aImg.src = this.imgUrl
                    // document.body.appendChild(aImg)

                    // 3、解决截图不全的问题，注释掉的是最初截图生成的图片直接显示在最下面了，需要的样式是覆盖当前页面并可以滚动
                    this.imgUrl = canvas.toDataURL('image/jpeg')
                    // const aImg = document.createElement('img')
                    this.querySuccess = true
                    const aImg = document.getElementById('canvasPic')
                    // aImg.style = 'border:1px solid #000;width: 90%;height: auto;-webkit-touch-callout: none;vertical-align: top;position: fixed;top: 30;margin-left: 20'
                    aImg.style = 'width: 100%;height: auto;-webkit-touch-callout: none;'
                    aImg.src = this.imgUrl
                    this.loadSuccess = false
                    // document.body.appendChild(aImg)
                }, 'image/png')
            });
        },
        getQueryVariable(variable) {
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        }
    }
}
</script>

<style>
* { margin:0; padding:0; }
.back{
    width: 100%;
}
.file{
    position: absolute;
    width: 69vw;
    height: 42vw;
}
.qr_code{
    position: absolute;
    bottom: 23vw;
    left: 36vw;
    width: 30vw;
    border-radius: 50%;
}
</style>
